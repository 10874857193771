import React from "react";

const Param = ({ n }) => {
  var color = null;
  switch (n) {
    case "k":
      color = "pink";
      break;
    case "v":
      color = "blue";
      break;
    case "a":
      color = "green";
      break;
    default:
      color = "text";
  }

  return (
    <code style={{ fontWeight: 800, color: `var(--color-${color})` }}>
      {`'${n}`}
    </code>
  );
};

export default Param;
