import React from "react";
import { ThemeContext } from "./ThemeContext";

const DarkImg = ({ src, img, alt, style, height, ...props }) => {
  const { colorMode } = React.useContext(ThemeContext);

  const lightSrc = src.replace("%", "");
  const darkSrc = src.replace("%", "-dark");
  src = colorMode === "dark" ? darkSrc : lightSrc;

  img = (
    <img
      src={src}
      alt={alt}
      style={{ height: height, textAlign: "center", ...style }}
      {...props}
    />
  );

  // If we know what the height of the image will be, we can add a wrapping box
  // while it's loading to avoid pop-in.
  return height ? <div style={{ height: height }}>{img}</div> : img;
};

export { DarkImg };
export default DarkImg;
