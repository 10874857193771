require("focus-visible/dist/focus-visible.js");
require("./static/css/highlight/prism-vs.css");
require("./static/css/highlight/prism-vsc-dark-plus.css");

const React = require("react");
const { App } = require("./src/components/App");

export const wrapRootElement = ({ element }) => {
  return <App>{element}</App>;
};
