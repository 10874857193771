import React from "react";
import styled from "styled-components";
import DarkImg from "@components/DarkImg";

const TriangleSingle = ({ name, alt, comment }) => (
  <Wrapper>
    <TitleWrapper>
      <code
        style={{
          textTransform: "capitalize",
          background: "none",
          fontWeight: 800,
          color: "var(--color-text)"
        }}
      >
        {name}
      </code>
    </TitleWrapper>
    <DarkImg
      src={`/posts/generalised-signatures/dep-${name}%.png`}
      alt={alt}
      height="160px"
      style={{ maxWidth: "100%" }}
    />
    <Comment>{comment}</Comment>
  </Wrapper>
);

const TriangleGrid = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <table
        style={{
          borderCollapse: "collapse",
          borderStyle: "hidden"
        }}
      >
        <tbody>
          <tr>
            <Cell>
              <TriangleSingle
                name="poly_hash"
                comment={
                  <>
                    Keys and value types constrain <code>t</code> at call-sites.
                  </>
                }
              />
            </Cell>
            <Cell>
              <TriangleSingle
                name="mono_hash"
                comment={
                  <>
                    The value type constrains <code>t</code>, but{" "}
                    <code>key</code>
                    is fixed by a functor.
                  </>
                }
              />
            </Cell>
          </tr>
          <tr>
            <Cell>
              <TriangleSingle
                name="persistent"
                comment={<>Both key and value types are fixed by a functor.</>}
              />
            </Cell>
            <Cell>
              <TriangleSingle
                name="universal"
                comment={
                  <>
                    Each key's type constrains the corresponding value's type.
                  </>
                }
              />
            </Cell>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const Wrapper = styled.div`
  text-align: center;
`;

const TitleWrapper = styled.div`
  padding-bottom: 15px;
`;

const Cell = styled.td`
  vertical-align: top;
  transition: border-color 0.25s linear;
  border: 1px solid var(--color-gray3) !important;
  padding: 20px !important;
`; // TODO: fix precedence of table styles

const Comment = styled.div`
  font-style: italic;
  max-width: 220px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
  line-height: 1.5;
`;

export { TriangleSingle };
export default TriangleGrid;
