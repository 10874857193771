const ocGray = [
  "#f8f9fa",
  "#f1f3f5",
  "#e9ecef",
  "#dee2e6",
  "#ced4da",
  "#adb5bd",
  "#868e96",
  "#495057",
  "#343a40",
  "#212529"
];

export const COLORS = {
  text: {
    light: "#3a343a",
    dark: "#fafafa"
  },
  foreground: {
    light: "#f5f7ff",
    dark: "#2e3440"
  },
  background: {
    light: "#fff",
    dark: "#181B20"
  },
  code: {
    light: "#5e6687",
    dark: "#c1c5d5"
  },
  codeBackground: {
    light: "#dfe2f1",
    dark: "#ff0000"
  },
  pink: {
    light: "hsl(333deg, 100%, 45%)",
    dark: "hsl(333deg, 100%, 52%)"
  },
  blue: {
    light: "#3d8fd1",
    dark: "hsl(230deg, 100%, 67%)"
  },
  green: {
    light: "hsl(160deg, 100%, 40%)",
    dark: "hsl(160deg, 100%, 40%)"
  },
  gray3: {
    light: ocGray[3],
    dark: ocGray[7]
  },
  gray900: {
    light: "hsl(225deg, 25%, 20%)",
    dark: "hsl(225deg, 16%, 90%)"
  },
  gray1000: {
    light: "hsl(225deg, 15%, 15%)",
    dark: "hsl(225deg, 25%, 95%)"
  }
};

export const COLOR_MODE_KEY = "color-mode";
export const INITIAL_COLOR_MODE_CSS_PROP = "--initial-color-mode";
