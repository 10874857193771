import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
  :root {
    --theme-transition: color 0.25s linear, background 0.25s linear, border-color 0.25s linear
  }

  body {
    transition: var(--theme-transition);
  }
`;
