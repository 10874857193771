import React from "react";
import { StaticQuery, Link, graphql } from "gatsby";
import { postPath, filterPosts } from "../utils";

import "./BlogIndex.css";

const moment = require("moment");

const BlogIndex = () => {
  return (
    <StaticQuery
      query={graphql`
        query BlogIndexQuery {
          posts: allMdx(
            filter: { fileAbsolutePath: { regex: "/data/posts/[^/]*.md$/" } }
          ) {
            edges {
              node {
                body
                frontmatter {
                  title
                  date
                  isPublished
                }
                fields {
                  readingTime {
                    minutes
                  }
                }
              }
            }
          }
        }
      `}
      render={({ posts }) => {
        return (
          <ul>
            {filterPosts(posts.edges).map(({ node }, index) => {
              let { title, date } = node.frontmatter;
              const duration = `(~ ${Math.round(
                node.fields.readingTime.minutes
              )} min)`;

              date = moment(date);

              const path = postPath(title, date);
              const dateString = date.format("MMMM Do, YYYY");

              return (
                <li className="index-post" key={index}>
                  <Link to={path}>
                    <strong>{title}</strong>
                  </Link>
                  {" –"} {dateString}
                  <span className="duration">{duration}</span>
                </li>
              );
            })}
          </ul>
        );
      }}
    />
  );
};

export default BlogIndex;
