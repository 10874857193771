// Implementation ported from an OCaml version contained within tarides.com/jake
function slugify(string) {
  if (string == null || string === "")
    throw Error("Cannot slugify an empty string");

  let lastCharIsDash = false;
  let slugifiedString = "";

  [...string.toLowerCase()].forEach(c => {
    if (c.match(/^[0-9a-z]$/)) {
      if (lastCharIsDash) {
        slugifiedString += "-";
        lastCharIsDash = false;
      }
      slugifiedString += c;
    } else {
      lastCharIsDash = true;
    }
  });

  return slugifiedString;
}

export const slugifyJosh = (str = "") => {
  let slug = str
    .toLowerCase()
    .replace(/\s/g, "-")
    .replace(/[^a-zA-Z0-9-]/g, "");

  // If the value starts with a number, swap it out!
  // Doing this in a dumb way for now.
  if (slug.match(/^[\d]{1,2}-/)) {
    slug = slug.replace(/^[\d]{1,2}-/, "digit");
  }

  return slug;
};

export const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  return function(...args) {
    if (!lastRan) {
      func.apply(null, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function() {
        if (Date.now() - lastRan >= limit) {
          func.apply(null, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

function slug(date, title) {
  if (!date.isValid)
    throw Error(`Invalid date ${JSON.stringify(date)} passed as input`);

  // date = date.format("YYYY-MM-DD");
  // return `${date}-${slugify(title)}`;

  return slugify(title);
}

export const postPath = (title, date) => {
  return `/posts/${slug(date, title)}`;
};

export const filterPosts = posts =>
  posts
    .sort(
      (a, b) =>
        new Date(b.node.frontmatter.date) - new Date(a.node.frontmatter.date)
    )
    .filter(
      ({ node }) =>
        node.frontmatter.isPublished || process.env.NODE_ENV !== "production"
    );
